import { Pipe, PipeTransform } from '@angular/core';

import { currencyFormat } from '@husky/shared-util-currency';

@Pipe({
  name: 'currencyFormat',
  standalone: true,
})
export class CurrencyFormatPipe implements PipeTransform {
  transform(value: string, precision?: number): unknown {
    return currencyFormat(value, {
      currencySymbol: '€',
      precision: precision ?? 2,
    });
  }
}
